<script>
import { mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'ManagementWebhookEdit',
  components: {
    ContentHeader: () => import('@/components/general/ContentHeader'),
    Action: () => import('@/components/general/Action'),
    ActionBar: () => import('@/components/general/ActionBar'),
    InputField: () => import('@/components/general/InputField')
  },

  data () {
    return {
      formData: {
        name: ''
      }
    }
  },

  validations: {
    formData: {
      name: { required }
    }
  },

  methods: {
    ...mapActions([
      'setFeedback',
      'attemptCreateWebhook'
    ]),

    leave () {
      this.$router.push({ name: 'management.webhooks' })
    },

    submit () {
      this.$v.$touch()

      const isValid = !this.$v.$invalid

      if (isValid) {
        this.$route.params.id ? this.updateWebhook() : this.createWebhook()

        return
      }

      this.setFeedback({ message: 'Preencha todos os campos obrigatórios' })
    },

    createWebhook () {
      this.attemptCreateWebhook(this.formData)
        .then(() => {
          this.setFeedback({
            message: 'Webhook criado com sucesso'
          })

          this.$router.push({ name: 'management.webhooks' })
        })
        .catch(() => {
          this.setFeedback({ message: 'Erro ao criar Webhook' })
        })
    }
  }
}
</script>

<template>
  <div class="main-content management-create">
    <ContentHeader
      title="Novo Webhook"
      dark-theme
      fixed
    >
      <Action
        slot="back"
        type="button"
        text="Voltar para Webhooks"
        class="btn-back"
        icon="keyboard_backspace"
        @click="leave()"
      />
      <ActionBar slot="actionbar" />
      <template slot="buttons">
        <Action
          :text="$t('global:form.save')"
          type="button"
          dark
          flat
          @click="submit"
        />
      </template>
    </ContentHeader>

    <div class="main-content-inner-big center-small">
      <div class="management-create">
        <div class="management-create-header">
          <p class="management-create-description">
            <strong>Dados</strong> do Webhook
          </p>
        </div>
      </div>

      <form @submit.prevent="submit">
        <InputField
          v-model="formData.name"
          label="Endereço"
          :validation="$v.formData.name"
          under-description="https://example.com/webhook"
        />
      </form>
    </div>
  </div>
</template>

<style src="@/assets/styles/themes/default/management.css"></style>
